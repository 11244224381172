<template>
    <nl-loader v-if="loading" :show="true" />

    <div v-else class="box">
        <AddEditCategorie class="mb-2" @reload="init" />
        <b-table v-if="list.length && !loading" :data="orderedList" :striped="true" :paginated="false">
            <b-table-column label="Name FR" field="name_fr">
                <div slot="default" slot-scope="props">
                    {{ props.row.parent_id ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : ''
                    }}{{ getTranslate(props.row, 'fr') }}
                </div>
            </b-table-column>
            <b-table-column label="Name EN" field="name_en">
                <div slot="default" slot-scope="props">
                    {{ props.row.parent_id ? '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' : ''
                    }}{{ getTranslate(props.row, 'en') }}
                </div>
            </b-table-column>
            <b-table-column label="Parent" field="parent">
                <div slot="default" slot-scope="props">
                    {{ getParent(props.row) ? getParent(props.row).name : '' }}
                </div>
            </b-table-column>
            <b-table-column label="Thématique" field="is_thematique">
                <div slot="default" slot-scope="props">
                    {{ props.row.is_thematique ? 'Oui' : 'Non' }}
                </div>
            </b-table-column>

            <b-table-column label="" field="actions">
                <div slot="default" slot-scope="{ row }">
                    <AddEditCategorie class="mr-1" :categorie="row" @reload="init">
                        <i class="fas fa-pen" />
                    </AddEditCategorie>
                    <button class="button is-danger" @click="deleteItem(row)">
                        <i class="fas fa-times" />
                    </button>
                </div>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import AddEditCategorie from '../../components/Admin/Categorie/AddEditCategorie.vue'
    import { mapActions } from 'vuex'

    export default {
        title: 'global.categories',
        components: {
            AddEditCategorie
        },
        data: () => ({
            list: [],
            loading: false
        }),
        computed: {
            parents() {
                return this.list.filter(item => item.parent_id == null)
            },
            orderedList() {
                const list = []
                this.parents.forEach(parent => {
                    list.push(parent)
                    this.getChildren(parent).forEach(child => {
                        list.push(child)
                    })
                })
                return list
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            ...mapActions('group', {
                getGroups: 'adminList',
                deleteCategorie: 'delete'
            }),
            init() {
                this.loading = true
                this.list = []
                this.getGroups()
                    .then(({ groups }) => {
                        this.list = groups
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            getChildren(parent) {
                return this.list.filter(item => item.parent_id == parent.id)
            },
            getParent(child) {
                return this.list.find(item => item.id == child.parent_id)
            },
            getTranslate(item, lang) {
                return item.translations.find(t => t.lang == lang).name
            },
            deleteItem(item) {
                if (confirm("Etes-vous sûr d'effacer cette catégorie et ses enfants ?")) {
                    this.deleteCategorie(item.id).then(() => {
                        this.init()
                    })
                }
            }
        }
    }
</script>

<style></style>
